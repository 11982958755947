import { gql } from 'urql';

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    addressLine1
    addressLine2
    city
    postalCode
    state
  }
`;

export const DELIVERY_FRAGMENT = gql`
  fragment DeliveryFragment on Delivery {
    id
    sequenceNumber
    totalWeight
    totalVolume
    location {
      id
      membershipTier
      address {
        latitude
        longitude
        state
      }
      businessName
      isDoordash
      locationDeliveryWindow {
        startTime
        endTime
      }
      routingCohort {
        id
        color
        name
      }
    }
    route {
      id
      name
      truck {
        maxWeight
        maxVolume
        vin
        vehicleTag
      }
    }
  }
`;

export const PICKUP_FRAGMENT = gql`
  fragment PickUpFragment on PickUp {
    id
    sequenceNumber
    vendor {
      name
      address {
        latitude
        longitude
        state
      }
    }
  }
`;

export const REDELIVERY_FRAGMENT = gql`
  fragment RedeliveryFragment on Redelivery {
    id
    sequenceNumber
    deliveryStop {
      id
    }
    location {
      id
      address {
        latitude
        longitude
        state
      }
      businessName
      isDoordash
      locationDeliveryWindow {
        startTime
        endTime
      }
      routingCohort {
        id
        color
        name
      }
    }
    route {
      id
      name
      truck {
        maxWeight
        maxVolume
        vin
        vehicleTag
      }
    }
  }
`;

export const ROUTE_FRAGMENT = gql`
  ${DELIVERY_FRAGMENT}
  ${PICKUP_FRAGMENT}
  ${REDELIVERY_FRAGMENT}
  fragment RouteFragment on Route {
    color
    id
    name
    isLobby
    nightEnd
    stops {
      __typename
      ... on Delivery {
        ...DeliveryFragment
      }
      ... on PickUp {
        ...PickUpFragment
      }
    }
    redeliveryStops {
      ...RedeliveryFragment
    }
    driverUser {
      id
      name
    }
    helperDriverUser {
      id
      name
    }
    totalVolume
    totalWeight
    truck {
      maxVolume
      maxWeight
      vin
      vehicleTag
    }
    percentItemsWithWeight
    percentItemsWithVolume
    redeliveryRoute
    routeOrigin
    routeCohort
  }
`;

export const ISSUE_FRAGMENT = gql`
  fragment IssueFragment on Issue {
    id
    note
    type
    description
  }
`;

export const ORDER_LINE = gql`
  fragment OrderLineFragment on OrderLine {
    id
    orderUnitQuantity
    deliveredQuantity
    quantityType
    itemsPerLot
    sku {
      id
      name
      refrigerationState
    }
    fulfillmentQuantity
    unitCostCents
  }
`;

export const WAREHOUSE_OPS_ORDER = gql`
  fragment OrderFragment on Order {
    deliveredAt
    partiallyDelivered
    totalItemQuantity
    vendor {
      isWarehouse
    }
    appliedFees {
      feeId
      feeAmountCents
    }
    orderLines {
      orderUnitQuantity
      unitCostCents
      sku {
        id
        item {
          id
          ... on InventoryItem {
            purchasePrice {
              cents
            }
            purchaseUnit {
              id
              conversionRate
            }
            saleUnit {
              id
              conversionRate
            }
          }
          ... on NonInventoryItem {
            purchasePrice {
              cents
            }
          }
        }
      }
    }
  }
`;
