import { TableHeader } from 'src/components/Table';
import { useQuery } from 'urql';
import { GET_LOCATIONS } from 'src/graphql/requests/getLocations';
import { selectedWarehouseSelector } from 'src/state/selectors/delivery';
import {
  GGetCohortsQuery,
  GGetLocationsQuery,
} from 'src/graphql/types/generated';
import { useAppSelector } from 'src/hooks/store';
import LocationRow from './LocationRow';
import { Flex } from 'theme-ui';
import { useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { GetLocation } from 'src/graphql/types/delivery';
import { tableStyle } from '../Table/style';
import {
  NativeSelect,
  Autocomplete,
  Spacer,
  TextInput,
  Button,
} from '@odekoteam/doppio';
import SearchSvg from 'assets/svgs/search.svg';
import Loader from './Loader';
import {
  DeliveryWindowFilter,
  Item,
  RoutingFilter,
  allCitiesItem,
  allCohortsItem,
  allStatesItem,
  alphabeticalByName,
  deliveryWindowFilterItems,
  getFilteredLocations,
  noCohortsItem,
  routingFilterItems,
  uniqueItems,
} from './utils';
import { useDispatch } from 'react-redux';
import { ModalType, showModal } from 'src/state/slices/modal';
import { GET_COHORTS } from 'src/graphql/requests/getCohorts';
import { LoaderSpinner } from '../Loader';

const LocationsTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedWarehouse = useAppSelector(selectedWarehouseSelector);
  const [allLocations, setAllLocations] = useState<GetLocation[]>([]);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [numLocationsLoaded, setNumLocationsLoaded] = useState<number>(0);
  const [filteredLocations, setFilteredLocations] = useState<GetLocation[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<GetLocation[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  // filters
  const [searchValue, setSearchValue] = useState('');
  const [routingFilter, setRoutingFilter] = useState(
    RoutingFilter.allLocations,
  );
  const [deliveryWindowFilter, setDeliveryWindowFilter] = useState(
    DeliveryWindowFilter.allWindows,
  );
  const [stateFilter, setStateFilter] = useState(allStatesItem.value);
  const [cityFilter, setCityFilter] = useState(allCitiesItem.value);
  const [zipFilter, setZipFilter] = useState('');
  const [cohortFilter, setCohortFilter] = useState(allCohortsItem.value);
  // dropdown inputs
  const [stateItems, setStateItems] = useState<Item[]>([allStatesItem]);
  const [cityItems, setCityItems] = useState<Item[]>([allCitiesItem]);
  const [zipItems, setZipItems] = useState<Item[]>([]);
  const [cohortItems, setCohortItems] = useState<Item[]>([
    allCohortsItem,
    noCohortsItem,
  ]);

  const [{ data: cohortData, fetching: cohortFetching, error: cohortError }] =
    useQuery<GGetCohortsQuery>({
      query: GET_COHORTS,
      variables: {
        routePlanInput: {
          warehouseId: selectedWarehouse?.id,
          nightEnd: selectedWarehouse?.currentNightEnd,
        },
      },
      pause: !selectedWarehouse?.id,
    });

  const [{ data, fetching, error }] = useQuery<GGetLocationsQuery>({
    query: GET_LOCATIONS,
    variables: {
      input: {
        warehouseId: selectedWarehouse?.id,
      },
      first: 100,
      after: endCursor,
    },
    pause: !selectedWarehouse?.id,
  });

  const labels = [
    '<select>',
    'Location Id',
    'Location',
    'Delivery Window',
    'City',
    'State',
    'Zip Code',
    'Cohort',
    'Automatic Routing',
  ];

  const onHeaderSelected = (value: boolean): void => {
    let newSelectedLocations: GetLocation[] = [...selectedLocations];
    filteredLocations.forEach((filteredLocation) => {
      const locationIndex = selectedLocations.findIndex(
        (selectedLocation) => selectedLocation.id === filteredLocation.id,
      );
      const isLocationSelected = locationIndex !== -1;
      if (value && !isLocationSelected) {
        // if checked, add to selected locations
        newSelectedLocations = [...newSelectedLocations, filteredLocation];
      } else if (!value && isLocationSelected) {
        // if unchecked, add to selected locations
        newSelectedLocations = newSelectedLocations.filter(
          (newSelectedLocation) =>
            newSelectedLocation.id !== filteredLocation.id,
        );
      }
    });
    setSelectedLocations(newSelectedLocations);
  };

  const onUpdateLocationsButtonClicked = (): void => {
    dispatch(
      showModal({
        type: ModalType.BulkEditLocationsModal,
        dismissButtonLabel: 'Cancel',
        variables: {
          locations: selectedLocations,
          cohorts: cohortData?.routingImportPlan?.cohorts ?? [],
        },
      }),
    );
  };

  const itemContent = (
    _index: number,
    location?: GetLocation,
  ): JSX.Element | null => {
    if (location)
      return (
        <LocationRow
          location={location}
          searchValue={searchValue}
          setSelectedLocations={setSelectedLocations}
          selectedLocations={selectedLocations}
          cohorts={cohortData?.routingImportPlan?.cohorts}
        />
      );
    return null;
  };
  const fixedHeaderContent = (): JSX.Element => (
    <TableHeader
      labels={labels}
      onHeaderSelected={onHeaderSelected}
      isAllSelected={isAllSelected}
      setIsAllSelected={setIsAllSelected}
    />
  );
  const table = ({ style, ...props }: any): JSX.Element => (
    <table {...props} style={{ ...style, width: '96vw' }} />
  );

  // get all locations
  useEffect(() => {
    if (data?.customerLocations.nodes) {
      const validLocations = data.customerLocations.nodes.filter(
        (location) => location !== null,
      );
      setAllLocations([...allLocations, ...validLocations]);
      setNumLocationsLoaded(
        numLocationsLoaded + data.customerLocations.nodes.length,
      );
    }
    if (data?.customerLocations.pageInfo.hasNextPage) {
      setEndCursor(data.customerLocations.pageInfo.endCursor);
    }
  }, [data?.customerLocations.pageInfo.endCursor]);

  // reset filters on warehouse change
  useEffect(() => {
    setAllLocations([]);
    setNumLocationsLoaded(0);
    setEndCursor(undefined);
    setFilteredLocations([]);
    setSelectedLocations([]);
    setIsAllSelected(false);
    setSearchValue('');
    setRoutingFilter(RoutingFilter.allLocations);
    setDeliveryWindowFilter(DeliveryWindowFilter.allWindows);
    setStateFilter(allStatesItem.value);
    setCityFilter(allCitiesItem.value);
    setZipFilter('');
    setCohortFilter(allCohortsItem.value);
  }, [selectedWarehouse?.id]);

  // get the dropdown values
  useEffect(() => {
    // get states
    const myStateItems = allLocations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.state,
          label: location.address.state,
        }),
      )
      .filter(uniqueItems);
    setStateItems([allStatesItem, ...myStateItems]);
    // get cities
    const myCityItems = allLocations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.city,
          label: location.address.city,
        }),
      )
      .filter(uniqueItems);
    setCityItems([allCitiesItem, ...myCityItems]);
    // get zips
    const myZipCodeItems = allLocations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.postalCode,
          label: location.address.postalCode,
        }),
      )
      .filter(uniqueItems);
    setZipItems(myZipCodeItems);
    // get cohorts
    const myCohortItems =
      cohortData?.routingImportPlan?.cohorts
        ?.map(
          (cohort): Item => ({
            value: cohort.id,
            label: cohort.name,
          }),
        )
        .filter(uniqueItems) ?? [];
    setCohortItems([allCohortsItem, ...myCohortItems, noCohortsItem]);
  }, [data, selectedWarehouse?.id, cohortData]);

  // filter the locations we're showing based on the selected filters
  useEffect(() => {
    const locations = [...allLocations];
    // if no filters are applied
    if (
      searchValue === '' &&
      routingFilter === RoutingFilter.allLocations &&
      deliveryWindowFilter === DeliveryWindowFilter.allWindows &&
      stateFilter === allStatesItem.value &&
      cityFilter === allCitiesItem.value &&
      zipFilter === '' &&
      cohortFilter === allCohortsItem.value
    ) {
      setFilteredLocations(locations.sort(alphabeticalByName));
      return;
    }
    const myFilteredLocations = getFilteredLocations([...locations], {
      searchValue,
      routingFilter,
      deliveryWindowFilter,
      stateFilter,
      cityFilter,
      zipFilter,
      cohortFilter,
      cohorts: cohortData?.routingImportPlan?.cohorts,
    });
    setFilteredLocations(myFilteredLocations.sort(alphabeticalByName));
  }, [
    searchValue,
    routingFilter,
    deliveryWindowFilter,
    allLocations,
    stateFilter,
    cityFilter,
    zipFilter,
    cohortFilter,
    cohortData?.routingImportPlan?.cohorts,
  ]);

  return (
    <Flex sx={{ flexDirection: 'column', width: '100vw', marginTop: '20px' }}>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column', 'row'],
          justifyContent: 'space-between',
          marginBottom: '24px',
          width: '96vw',
          flexWrap: 'wrap',
          gap: '8px',
        }}
      >
        <Flex>
          <Button
            id="update-locations-button"
            isDisabled={selectedLocations.length === 0}
            onPress={onUpdateLocationsButtonClicked}
          >
            Edit {selectedLocations.length} locations
          </Button>
          <Spacer width="$1" />
          <TextInput
            iconLeft={<SearchSvg />}
            id="location-search"
            onChangeText={(value): void => setSearchValue(value)}
            placeholder="Search"
            value={searchValue}
          />
        </Flex>
        <Spacer height="$1" />
        <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <NativeSelect
            id="routing-input-controlled"
            items={routingFilterItems}
            value={routingFilter}
            onValueChange={(value): void =>
              setRoutingFilter(value as RoutingFilter)
            }
          />
          <Spacer width="$1" />
          <NativeSelect
            id="delivery-window-input-controlled"
            items={deliveryWindowFilterItems}
            value={deliveryWindowFilter}
            onValueChange={(value): void =>
              setDeliveryWindowFilter(value as DeliveryWindowFilter)
            }
          />
          <Spacer width="$1" />
          <NativeSelect
            id="cohort-input-controlled"
            items={cohortItems}
            value={cohortFilter}
            onValueChange={(value): void => setCohortFilter(value as string)}
          />
          <Spacer width="$1" />
          <NativeSelect
            id="state-input-controlled"
            items={stateItems}
            value={stateFilter}
            onValueChange={(value): void => setStateFilter(value as string)}
          />
          <Spacer width="$1" />
          <NativeSelect
            id="city-input-controlled"
            items={cityItems}
            value={cityFilter}
            onValueChange={(value): void => setCityFilter(value as string)}
          />
          <Spacer width="$1" />
          <Autocomplete
            id="zip-input-controlled"
            options={zipItems}
            value={zipFilter}
            placeholder="All Zip Codes"
            onChangeText={(value): void => setZipFilter(value)}
            onSelect={(option): void => setZipFilter(option.value as string)}
          />
        </Flex>
      </Flex>
      <div
        sx={{
          variant: 'text.paragraphLgDefault',
          marginBottom: '6px',
        }}
      >
        {numLocationsLoaded} of {data?.customerLocations.totalCount ?? 0}{' '}
        locations loaded
        {numLocationsLoaded !== data?.customerLocations.totalCount && (
          <LoaderSpinner
            imgStyle={{
              top: 'auto',
              left: 'auto',
              height: '20px',
              margin: '2px 10px',
            }}
          />
        )}
      </div>
      {fetching && allLocations.length === 0 ? (
        <Loader labels={labels} />
      ) : (
        <TableVirtuoso
          sx={{ ...tableStyle }}
          data={filteredLocations}
          useWindowScroll
          fixedHeaderContent={fixedHeaderContent}
          itemContent={itemContent}
          components={{
            Table: table,
          }}
        />
      )}
    </Flex>
  );
};

export default LocationsTable;
