import { gql } from 'urql';
import { PRODUCT } from '../client';

export const GET_COHORTS = gql`
  query ${PRODUCT}GetCohorts($routePlanInput: RoutingImportPlanInput!) {
    routingImportPlan(input: $routePlanInput) {
      cohorts {
        color
        id
        name
      }
    }
  }
`;
