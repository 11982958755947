import { gql } from 'urql';
import { PRODUCT } from '../client';
import { ROUTE_FRAGMENT } from './fragments';

export const EDIT_ROUTE_DETAILS = gql`
  mutation ${PRODUCT}UpdateRouteDetails($input: RouteDetailsUpdateInput!) {
    updateRouteDetails(input: $input) {
      route {
        id
        driverUser {
          id
          name
        }
        helperDriverUser {
          id
          name
        }
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const DELIVERY_STOPS_REORDER = gql`
  ${ROUTE_FRAGMENT}
  mutation ${PRODUCT}DeliveryStopsReorder($input: DeliveryStopsReorderInput!) {
    deliveryStopsReorder(input: $input) {
      userErrors {
        __typename
      }
      route {
        ...RouteFragment
      }
    }
  }
`;

export const MOVE_DELIVERY_STOPS_TO_ROUTE = gql`
${ROUTE_FRAGMENT}
  mutation ${PRODUCT}MoveDeliveryStopsToRoute($input: DeliveryStopsMoveToRouteInput!) {
    moveDeliveryStopsToRoute(input: $input) {
      updatedRoutes {
        ...RouteFragment
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const UPDATE_ROUTE_STOP_DRIVER_REMINDER = gql`
  mutation ${PRODUCT}UpdateRouteStopDriverReminder($input: DeliveryRouteStopReminderUpdateInput!) {
    updateRouteStopDriverReminder(input: $input) {
      stop {
        ... on Delivery {
          id
          reminderMessage
        }
        ... on PickUp {
          id
          reminderMessage
        }
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const CREATE_WAREHOUSE_DELIVERY_ROUTE = gql`
  ${ROUTE_FRAGMENT}
  mutation ${PRODUCT}CreateWarehouseDeliveryRoute($input: DeliveryRouteCreateInput!) {
    createWarehouseDeliveryRoute(input: $input) {
      route {
        ...RouteFragment
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const UPDATE_ORDER_WAREHOUSE_NOTE = gql`
  mutation ${PRODUCT}UpdateOrderWarehouseNote($input: OrderWarehouseNoteUpdateInput!) {
  updateOrderWarehouseNote(input: $input) {
    userErrors {
      ... on OrderNotFound {
        message
      }
    }
    order {
      warehouseNote
    }
  }
}
`;

export const UPDATE_ROUTING_IMPORT_PLAN_TRUCK_COUNT = gql`
  mutation ${PRODUCT}UpdateRoutingImportPlanTruckCount($input: RoutingImportPlanTruckCountUpdateInput!) {
    updateRoutingImportPlanTruckCount(input: $input) {
      routingImportPlan {
        id
        status
        truckCount
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const CREATE_REDELIVERY_STOPS = gql`
  mutation ${PRODUCT}CreateRedeliveryStops($input: RedeliveryStopCreateInput!) {
    createRedeliveryStops(input: $input) {
      redeliveryStops {
        id
        deliveryStatusDmp
        deliveryStop {
          id
        }
        route {
          id
          name
        }
      }
      userErrors {
        __typename
      }
    }
  }
`;

export const DELETE_REDELIVERY_STOP = gql`
  mutation ${PRODUCT}DeleteRedeliveryStop($input: RedeliveryStopDeleteInput!) {
    deleteRedeliveryStop(input: $input) {
      userErrors {
        __typename
      }
    }
  }
`;

export const UPDATE_ORDER_QUANTITY_DELIVERED = gql`
  mutation ${PRODUCT}UpdateOrderQuantityDelivered(
    $input: OrderQuantityDeliveredUpdateInput!
  ) {
    updateOrderQuantityDelivered(input: $input) {
      orderLines {
        id
      }
    }
  }
`;

export const TRIGGER_ROUTES_SYNC = gql`
  mutation ${PRODUCT}TriggerRoutesSync($input: TriggerRoutesSyncInput!) {
    triggerRoutesSync(input: $input) {
      triggered
      userErrors {
        __typename
      }
    }
  }
`;
