import {
  LoaderTable,
  Table,
  TableBody,
  TableHeader,
} from 'src/components/Table';

const Loader = ({ labels }: { labels: string[] }): JSX.Element => {
  return (
    <Table data-test-id="locations-table" style={{ width: '96vw' }}>
      <TableHeader labels={labels} />
      <TableBody>
        <LoaderTable numColumns={9} />
      </TableBody>
    </Table>
  );
};

export default Loader;
