import { gql } from 'urql';
import { PRODUCT } from '../client';

export const GET_LOCATIONS = gql`
  query ${PRODUCT}GetLocations($input: CustomerLocationsInput!, $first: Int, $after: String) {
    customerLocations(input: $input, first: $first, after: $after) {
      nodes {
        id
        keyId
        businessName
        membershipTier
        address {
          city
          state
          postalCode
        }
        includeInAutomatedRouting
        isDoordash
        locationDeliveryWindow {
          startTime
          endTime
        }
        routingCohort {
          id
          color
          name
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
